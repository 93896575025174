<template lang="pug">
div
  multiselect(
    :key="'select-image-'+ itemKey"
    v-model="image",
    label="file_name",
    track-by="id",
    :options="options",
    placeholder="搜尋影音庫檔案編號或標題關鍵字",
    @search-change="updateOptions"
  )
    template(slot="singleLabel", slot-scope="{ option }")
      img.option__image(:key="option.id + '-option-image'" v-if="type === 'image'" :src="option.url")
      span.option__desc(:key="option.id + '-option-title'")
        span.option__title {{ option.file_name }}

    template(slot="option", slot-scope="{ option }")
      img.option__image(:key="option.id + '-option-image'" v-if="type === 'image'" :src="option.url")
      .option__desc(:key="option.id + '-option-title'")
        span.option__title {{ option.file_name }}
        //- span.option__small {{ option.des }}

</template>
<script>
import Multiselect from 'vue-multiselect'
export default {
  components: {
    Multiselect
  },
  props: ['itemKey', 'value', 'type', 'index'],
  data () {
    return {
      image: this.value,
      options: []
    }
  },
  watch: {
    'image' (_image) {
      let _index = this.index
      this.$emit('upDateImage', _image, _index)
    },
    'value_compute' () {
      this.image = this.value_compute
    }
  },
  computed: {
    value_compute () {
      return this.value
    }
  },
  methods: {
    customLabel ({ title, desc }) {
      return `${title} – ${desc}`
    },
    updateOptions (_keyword) {
      let mediaType
      let vm = this
      this.options = []

      // 圖片型態檔案
      if (this.type === 'image') {
        mediaType = 1
      }

      // 影音型態檔案
      else if (this.type === 'video') {
        mediaType = 2
      }

      // console.log("=> 圖庫搜尋:", _keyword)
      this.$root.apis.getFileList({
        name: _keyword,
        type: mediaType
      }, function (_response) {
        let files = _response.data.files
        // console.log('=> 需要更新圖片清單: ' + files.length + "組");
        files.forEach(function (_image) {
          vm.options.push(_image)
        })
        // console.log('=> vm.options:', vm.options)
      })

    }
  }
}
</script>
<style lang="sass" scoped>
.option__image
  width: 100px
</style>
