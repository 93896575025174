<template lang="pug">
Layout
  PageHeader(:title="title", :items="items")
  .row
    .col-lg-12.mb-2.title-box
      b-button.width-md(variant="secondary" to="/wancionexchange") 回上一層
    .col-lg-12.mt-2
      b-card.mb-1(no-body)
        b-card-header(header-tag="header", role="tab")
          .header-row
            .header-title 內容設定

        b-card-body
          .row
            .col-10.row
              .col-6
                .form-group.mb-3
                  label 兌換類別
                  br
                  multiselect(
                    v-model="currentPromotionalType"
                    :options="options",
                    :allow-empty="false",
                    deselect-label="Can't remove this value"
                    placeholder="選擇兌換類別"
                    trackBy="id"
                    label="name"
                    select-label=""
                  )

            .col-10.row(v-if="promotionData.type == 2")
              .col-6
                .form-group.mb-3
                  label VVVIP 兌換項目
                  br
                  select.form-control(v-model="promotionData.code")
                    option(value="") 請選擇需要設定的票券項目
                    option(
                      v-for="( caseItem, index ) in vvvipCaseList" 
                      :value="caseItem.id"
                    ) 
                      span {{ caseItem.ticket.name }}
                      span  - ({{ caseItem.event.brand.name }} / {{ caseItem.event.name }})

            .col-10.row
              .col-6
                .form-group.mb-3
                  label 兌換品名 (範例：快點購happy券-100元)
                  br
                  input.form-control(
                    v-model="promotionData.name"
                    type="text",
                    placeholder="請輸入名稱，例如輸入快點購折價券，前台會自動將金額帶入"
                  )
            .col-10.row
              .col-4
                .form-group.mb-3
                  label 金額
                  br
                  input.form-control(
                    :disabled="isEditMode"
                    :value="$root.common.formatNumber(promotionData.price, 0)"
                    @input="(_event) => promotionData.price = _event.target.value.toString().replace(/,/g, '')"
                    type="text",
                    placeholder="請輸入金額"
                  )
              .col-4(v-if="promotionData.type == 1")
                .form-group.mb-3
                  label 產製數量
                  br
                  input.form-control(
                    :disabled="isEditMode"
                    :value="$root.common.formatNumber(promotionData.stock, 0)"
                    @input="(_event) => promotionData.stock = _event.target.value.toString().replace(/,/g, '')"
                    type="text",
                    placeholder="請輸入數量"
                  )
              .col-4(v-if="promotionData.type == 2")
                .form-group.mb-3
                  label 庫存數量
                  br
                  input.form-control(readonly type="text" :value="promotionData.stock")

              .col-4
                .form-group.mb-3
                  label 需兌換旺幣
                  br
                  input.form-control(
                    :value="$root.common.formatNumber(promotionData.wancoin, 0)"
                    @input="(_event) => promotionData.wancoin = _event.target.value.toString().replace(/,/g, '')"
                    type="text",
                    placeholder="請輸入需要用戶兌換的旺幣數量"
                  )
              .col-lg-12.mb-3.row 
                .col(v-if="!promotionData.image")
                  vue-dropzone#dropzone(
                    ref="myVueDropzone", 
                    :use-custom-slot="true", 
                    :options="dropzoneOptions"
                    @vdropzone-success-multiple="uploadImageSuccess"
                  )
                    .dz-message.needsclick
                      i.h1.text-muted.ri-upload-cloud-2-line
                      h4 上傳圖片
                  p.mb-0.mt-1 (尺吋：270x200)
                .p-2.mb-3(v-else)
                  .d-flex.flex-column
                    .d-md-flex.align-items-end.justify-content-center
                      .dropzone-previews.mr-2.my-2
                        .card.mb-0.shadow-none
                          img.preview-img.avatar-sm.rounded.bg-light(:src='promotionData.image' alt='')
                      b-button.width-lg.my-2.mx-2(variant="primary" @click="promotionData.image = ''") 重新上傳
                    p.col.mb-0.mt-1 (尺吋：270x200)

              .col-12.mb-2
                .form-group.mb-3
                  label 獎勵說明
                  br
                  textarea.form-control(
                    v-model="promotionData.content",
                    rows="5",
                    type="text",
                    placeholder="請輸入說明"
                  )
              .col-12.mb-2
                .form-group.mb-3
                  label 票券使用說明
                  br
                  textarea.form-control(
                    v-model="promotionData.use_content",
                    rows="8",
                    type="text",
                    placeholder="請輸入說明"
                  )
              .col-lg-6
                .form-group.mb-3
                  label 任務開始日期
                  date-picker(
                    type="datetime"
                    :is-24="true"
                    v-model="promotionData.startDateTime",
                    format="YYYY-MM-DD HH:mm"
                    placeholder="請選擇日期"
                  )
              .col-lg-6
                .form-group.mb-3
                  label 任務結束日期
                  date-picker(
                    type="datetime"
                    :is-24="true"
                    v-model="promotionData.endDateTime",
                    format="YYYY-MM-DD HH:mm"
                    placeholder="請選擇日期"
                  )

        .row.m-3.d-flex.justify-content-start
          .col.px-0(v-if="isEditMode")
            b-button.width-lg.mr-1(variant="primary" @click="onSave" :disabled="saving") {{ saving ? '儲存中...' : '更新兌換項目' }}
            b-button.width-lg.mr-1(v-if="!promotionData.status" variant="primary" @click="onPublish" :disabled="releasing") {{ releasing ? '上架中...' : '上架' }}
            b-button.width-lg(v-else variant="primary" @click="onPublish" :disabled="releasing") {{ releasing ? '下架中...' : '下架' }}
          .col.px-0(v-else)
            b-button.width-lg.mr-1(variant="primary" @click="onSave" :disabled="saving") {{ saving ? '產製中...' : '建立兌換項目' }}
</template>

<script>
import Layout from "../../../layouts/main";
import PageHeader from "@/components/Page-header";
import Multiselect from "vue-multiselect";
import DatePicker from "vue2-datepicker";
import Select2 from 'v-select2-component';
import SelectImage from "@/components/select-image";
import draggable from 'vuedraggable'
import vue2Dropzone from "vue2-dropzone";

/**
 * Starter component
 */
export default {
  head () {
    return {
      title: `${this.title} | Minton - Nuxtjs Responsive Admin Dashboard Template`,
    };
  },
  data () {
    return {
      isEditMode: false,
      promotionData: {
        id: null,
        name: "",
        image: "",
        content: "",
        wancoin: 0,
        stock: 0,
        price: 0,
        payedCount: 0,
        use_content: "",
        startDateTime: null,
        endDateTime: null,
        updatedAt: null,
        status: false,
        type: 1,
        code: ''
      },
      options: [
        {
          id: 1,
          name: "快點購折價券"
        },
        {
          id: 2,
          name: "VVVIP電子票券庫"
        }
      ],
      vvvipCaseList: [],
      dropzoneOptions: {
        url: process.env.VUE_APP_UPLOAD_SERVER_URI + "/media/create/news/default",
        method: "post",
        uploadMultiple: true,
        paramName: "files",
        headers: {
          "Authorization": 'Bearer ' + this.$root.common.getCookie('api-token'),
        },
        previewTemplate: this.template(),
      },
      saving: false,
      releasing: false,
      currentPromotionalType: null,

      date: '',
      time: '',
      visible: true,
      option: '',


      tableData: [],

      selected: '單選',


      option_list: [
        {
          text: ''
        },
        {
          text: ''
        },
        {
          text: ''
        }
      ]


    };
  },
  computed: {
    title () { return this.isEditMode ? "編輯兌換項目" : "新增兌換項目" },
    items () {
      return [
        {
          text: "旺幣兌換設定",
          href: "/wancionexchange",
        },
        {
          text: this.isEditMode ? "編輯兌換任務" : "新增兌換任務",
          active: true,
        }
      ]
    },
    currentPromotionalId () { return this.$route.params?.promotionalItemHashID },
  },
  mounted () {
    console.log(this.$route.params)
    this.isEditMode = this.$route.params?.promotionalItemHashID
    if (this.isEditMode) {
      this.getWancoinExchangeDetail()
    }
  },
  components: {
    Layout,
    PageHeader,
    Multiselect,
    DatePicker,
    Select2,
    SelectImage,
    draggable,
    vueDropzone: vue2Dropzone,
  },
  watch: {
    async 'currentPromotionalType' (_type) {
      // 判斷是否需要拉取清單
      if (_type.id == 2) {
        await this.getVVVIPAuthorizeCaseList()
      }
      // 更新項目種類
      this.promotionData.type = await _type.id
    },
    'promotionData.code' (_code) {
      if (this.promotionData.type == 1) {
        return
      }

      let vm = this
      this.vvvipCaseList.forEach(function (_item, _index) {
        if (_item.id === _code) {
          vm.promotionData.name = _item.ticket.name
          vm.promotionData.image = _item.event.image[0]
          vm.promotionData.stock = _item.ticket.stock
          vm.promotionData.use_content = _item.event.description
          vm.promotionData.startDateTime = vm.$root.common.getNowTime('Object', _item.authorizeStartDate + ' 00:00:00')
          vm.promotionData.endDateTime = vm.$root.common.getNowTime('Object', _item.authorizEendDate + ' 23:59:59')
        }
      })
    }
  },
  methods: {
    getWancoinExchangeDetail () {
      const vm = this
      this.$root.apis.getWancoinExchangeItem(this.currentPromotionalId,
        function (_response) {
          const result = _response.body.data

          let searchItem
          vm.options.forEach(function (_option) {
            if (_option.id === result.type) {
              vm.currentPromotionalType = _option
            }
          })


          vm.promotionData.id = result.id
          vm.promotionData.code = result.code
          vm.promotionData.name = result.name
          vm.promotionData.image = result.image
          vm.promotionData.content = result.content
          vm.promotionData.wancoin = result.wancoin
          vm.promotionData.stock = result.stock
          vm.promotionData.price = result.price
          vm.promotionData.payedCount = result.payedCount
          vm.promotionData.use_content = result.use_content
          vm.promotionData.startDateTime = vm.$root.common.getNowTime('Object', `${result?.start_date} ${result?.start_time}`)
          vm.promotionData.endDateTime = vm.$root.common.getNowTime('Object', `${result?.end_date} ${result?.end_time}`)
          vm.promotionData.updatedAt = result.updatedAt
          vm.promotionData.status = result.status
        },
        function (_error) {
          console.error(_error)
          vm.$root.common.showErrorDialog(_error.body.data)
        }
      )
    },
    // 處理圖片下載模組
    template () {
      return ` <div class="dropzone-previews">
            <div class="card mb-0 shadow-none">
              <img data-dz-thumbnail src="#" class="preview-img avatar-sm rounded bg-light" alt="">
              <!-- Button -->
              <a href="" class="img-del-btn btn btn-link btn-lg text-muted" data-dz-remove>
                  <i class="fe-x"></i>
              </a>
            </div>
        </div>`;
    },
    // 圖片上傳成功時
    uploadImageSuccess (file, response) {
      this.promotionData.image = response.data[0].url
    },
    checkRequiredFields () {
      const required = ['type', 'name', 'image', 'content', 'wancoin', 'price', 'stock', 'startDateTime', 'endDateTime']
      const invalid = required.some(_field => this.promotionData[_field] === null || this.promotionData[_field] === undefined || this.promotionData[_field] === '')
      if (invalid) {
        this.$root.common.showErrorDialog('請確認所有欄位都有填寫完成喔！')
        return false
      } else {
        return true
      }
    },
    onSave () {
      if (!this.checkRequiredFields()) return
      const data = {
        type: this.promotionData.type,
        name: this.promotionData.name,
        image: this.promotionData.image,
        content: this.promotionData.content,
        wancoin: this.promotionData.wancoin,
        price: this.promotionData.price,
        stock: this.promotionData.stock,
        wancoin: this.promotionData.wancoin,
        use_content: this.promotionData.use_content,
        code: this.promotionData.code,
        start_date: this.$root.common.getNowTime('date', this.promotionData.startDateTime),
        start_time: this.$root.common.getNowTime('time', this.promotionData.startDateTime),
        end_date: this.$root.common.getNowTime('date', this.promotionData.endDateTime),
        end_time: this.$root.common.getNowTime('time', this.promotionData.endDateTime),
      }
      this.isEditMode ? this.updateWancoinExchangeDetail(data) : this.createWancoinExchangeDetail(data)
    },
    onPublish () {
      if (!this.checkRequiredFields()) return
      this.promotionData.status ? this.setDownWancoinExchangeItem() : this.setUpWancoinExchangeItem()
    },
    setUpWancoinExchangeItem () {
      const vm = this
      this.$root.common.confirmAction("上架兌換項目", "是否確定要上架？", "上架", "再想一下",
        function (_result) {
          vm.$root.apis.setupWancoinExchangeItem(
            vm.currentPromotionalId,
            function (_response) {
              vm.$root.common.showSingleNotify("上架成功")
              vm.$router.push('/wancionexchange')
            },
            function (_error) {
              console.error(_error)
              vm.$root.common.showErrorDialog(_error.body.data)
            }
          )
        }
      )
    },
    setDownWancoinExchangeItem () {
      const vm = this
      this.$root.common.confirmAction("下架兌換項目", "是否確定要下架？", "下架", "再想一下",
        function (_result) {
          vm.$root.apis.setdownWancoinExchangeItem(
            vm.currentPromotionalId,
            function (_response) {
              vm.$root.common.showSingleNotify("下架成功")
              vm.$router.push('/wancionexchange')
            },
            function (_error) {
              console.error(_error)
              vm.$root.common.showErrorDialog(_error.body.data)
            }
          )
        }
      )
    },
    updateWancoinExchangeDetail (_data) {
      this.saving = true
      const vm = this
      this.$root.apis.updateWancoinExchangeItem(
        this.currentPromotionalId,
        _data,
        function (_response) {
          vm.$root.common.showSingleNotify('更新成功')
          vm.saving = false
          vm.$router.push('/wancionexchange')
        },
        function (_error) {
          console.error(_error)
          vm.$root.common.showErrorDialog(_error.body.data)
          vm.saving = false
        }
      )
    },
    createWancoinExchangeDetail (_data) {
      this.saving = true
      const vm = this
      this.$root.apis.createWancoinExchangeItem(
        _data,
        function (_response) {
          vm.$root.common.showSingleNotify('新增成功')
          vm.saving = false
          vm.$router.push('/wancionexchange')
        },
        function (_error) {
          console.error(_error)
          vm.$root.common.showErrorDialog(_error.body.data)
          vm.saving = false
        }
      )
    },
    async getVVVIPAuthorizeCaseList () {
      let vm = this
      await this.$root.apis.getVVVIPAuthorizeCaseList(function (_response) {
        let response = _response.body.data
        vm.vvvipCaseList = response.items
      })
    }
  }
};
</script>

<style lang="sass" scoped>
.title-box
  display: flex
  justify-content: space-between
  align-items: center
  .addbtn
    display: flex
    justify-content: center
    align-items: center
    .ri-file-add-fill
      font-size: 1.4rem
      line-height: 1.5rem
.card-header
  background: #fff
.label-select
  border: none
  padding: 0
  height: auto
  line-height: 1
  font-weight: 600
.header-row
  display: flex
  align-items: center
  .header-title
    margin: 0
    flex: 1

.tab-group
  // background: rgba(229,231,235,1)
  padding: 0.5rem 1rem
  button.off
    color: rgba(156,163,175,1)
    background: rgba(0,0,0,0)
    border-color: rgba(0,0,0,0)

.form-label-row
  display: flex
  gap: 14px

.add-btn
  width: 100%
  border: dashed 1px
.del-btn
  flex: 1
  display: flex
  align-items: center
  position: relative
  top: 3px
  .btn
    padding: 0rem .3rem
    border-radius: 50px
.img-view
  width: 400px
  height: 300px
  background: #e2e8f0
  border-radius: 5px
  margin-top: 5px
  border: 1px solid #ccc
  display: flex
  align-items: center
  justify-content: center
  position: relative
  i
    font-size: 30px

.gray-box
  background: #f1f5f9
  width: 100%
  padding: 10px
  display: flex
  justify-content: center

::v-deep .select2-container--default
  display: block
  width: 100%
  height: calc(1.5em + 0.9rem + 2px)
  // padding: 0.45rem 0.9rem
  font-size: 0.9rem
  font-weight: 400
  line-height: 1.5
  color: #6c757d
  background-color: #fff
  background-clip: padding-box
  border: 1px solid #ced4da
  border-radius: 0.2rem
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out
  outline: none

::v-deep .select2-selection--single
  border: none
  display: flex
  align-items: center
  height: 100%
.form-label-row
  display: flex
  gap: 14px
::v-deep .table-btn
  text-decoration: underline
  color: #0073b8
  background: none
  border: none
  padding: 0
::v-deep .width-auto
  min-width: 7rem
  white-space: nowrap
  vertical-align: middle
  &.center
    text-align: center

.option-item
  display: flex
  gap: 10px
  width: 100%
  align-items: center
  ::v-deep .custom-control-label::after
    top: 45%
  ::v-deep .custom-control-label::before
    top: 45%

::v-deep.vue-dropzone
  padding: 0
  width: 270px
  height: 200px
  
  .dz-message
    margin: 4em 0

::v-deep.dropzone-previews
  width: 270px
  height: 200px

  .preview-img
    width: 270px
    height: 200px
    object-fit: cover
  .img-del-btn
    position: absolute
    right: -15px
    top: -15px
    background: #1e40b0
    border-radius: 50%
    padding: 0
    height: 30px
    width: 30px
    line-height: 30px
    cursor: pointer
    i
      color: #fff
      cursor: pointer

</style>
